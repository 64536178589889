@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
}

pre, code {
  font-family: 'JetBrains Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  margin: 0;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.execute:hover {
  color: blue;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.outerGrid {
  -webkit-box-shadow: 6px 2px 34px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 2px 34px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 2px 34px -12px rgba(0, 0, 0, 0.75);
}

html,
body,
#app,
#root,
#app > div {
  height: 100vh
}

.cm-focused {
  outline: none !important;
}

.cm-editor {
  height: 100%;
}

.cm-gutters {
  min-height: inherit;
}

#delete-icon:hover {
  opacity: 0.6;
}
